import React from "react";
import Layout from '../components/layout';
import { CardProducts } from '../templates/common';
import ProductListItem from '../components/product-list-item';
// import mapProduct from '../common/map-product';
import useNewProducts from "../hooks/use-new-products";

export default () => {
  const productGroups = useNewProducts();

  return (
    <Layout
      title="What's New | PasteWax Pakistan"
      description="All new products launched in our store"
      slug="whats-new"
    >
      {
        productGroups.map((g, i) => {
          return (
            <div key={i}>
              <h2>{g[0].new.toDateString().substring(3)}</h2>
              <CardProducts>
                {g.map(p => {
                  return <ProductListItem key={p.slug} product={p}></ProductListItem>;
                })}
              </CardProducts>
            </div>
          );
        })
      }
      {/* <CardProducts>
        {products.map(p => {
          const product = mapProduct(p);
          return <ProductListItem key={product.slug} product={product}></ProductListItem>;
        })}
      </CardProducts> */}
    </Layout>
  );
};
