import { graphql, useStaticQuery } from 'gatsby';
import mapProduct from '../common/map-product';

const useNewProducts = () => {
  const data = useStaticQuery(graphql`
    query {
      allMdx(
        filter: {frontmatter: {new: {ne: null}}}
      ) {
        group(field: frontmatter___new) {
          nodes {
            frontmatter {
              new
              slug
              title
              description
              price
              images
            }
          }
        }
      }
    }
  `);

  return data.allMdx.group
    .map(g => g.nodes.map(n => {
      const p = mapProduct(n);
      p.new = new Date(n.frontmatter.new);
      return p;
    }))
    .reverse()
  ;
};

export default useNewProducts;
