import React from 'react';
import { Link } from 'gatsby';
import { css } from '@emotion/core';
import { getLegacyImage, imgAltFromSlug } from '../common/utils';

const ProductListItem = ({ product }) => {
  const defaultImage = `/static/images/${product.slug}/${product.images[0]}`;
  const padding = '20px';

  return (
    <article title={product.description}
      css={css`
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        padding: ${padding};
        position: relative;
        width: 25%;

        &::after {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          box-shadow: 1px 0 #eee, 0 1px #eee;
          z-index: 2;
          pointer-events: none;
        }

        .product-image-link {
          border: none;
        }
        .product-image {
          width: 100%;
          height: auto;
        }

        .product-link {
          border: none;
          color: #888;
          margin: 0.4rem 0 0 0;
        }
        .product-image-link:hover + .product-link,
        .product-link:hover {
          color: #666;
        }

        @media (max-width: 960px) {
          width: 33.3333%;
        }

        @media (max-width: 640px) {
          width: 50%;
        }

        @media (max-width: 430px) {
          width: 100%;
        }
      `}
    >
      {product.discontinued ? <span css={css`
        position: absolute;
        background: #888;
        color: #fff;
        font-size: .8rem;
        font-weight: 400;
        padding: 0 6px;
        right: ${padding};
      `}>
        Discontinued
      </span> : ''}
      <Link className="product-image-link" to={'/' + product.slug}>
        <picture>
          <source srcSet={defaultImage}></source>
          <img className="product-image" src={getLegacyImage(defaultImage)} loading="lazy" alt={imgAltFromSlug(product.slug)} />
        </picture>
      </Link>
      <Link className="product-link" to={'/' + product.slug}>{product.title}</Link>
      <span>PKR {product.price}</span>
    </article>
  );
};

export default ProductListItem;
