export default function mapProduct(product) {
  return {
    discontinued: product.frontmatter.discontinued,
    title: product.frontmatter.title,
    slug: product.frontmatter.slug,
    description: product.frontmatter.description,
    categories: product.frontmatter.categories,
    price: product.frontmatter.price,
    images: product.frontmatter.images,
  };
}
