import React from 'react';
import { css } from '@emotion/core';

const Card = ({ className, children }) => (
  <div className={className} css={css`
    background: #fbfbfb;
    border: 1px solid #eee;

    &.layout {
      padding: 20px;
    }

    .card-heading {
      margin-top: 0;
    }
  `}>
    {children}
  </div>
);

export default Card;
